import michael from './michael.jpg';
import taryn from './taryn.jpg';
import tracy from './tracy.jpg';
import izzi from './izzi.jpg';
import brandon from './brandon.jpg';
import ryan from './ryan.jpg';
import jennifer from './jennifer.jpg';
import jason from './jason.jpg';
import kat from './kat.jpeg';
import chris from './chris.jpg';

export default {
  brandon,
  chris,
  izzi,
  jason,
  jennifer,
  kat,
  michael,
  ryan,
  taryn,
  tracy,
}
