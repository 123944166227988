import React from 'react';
import { SessionsList, Wrapper } from '../components';

const Sessions = () => (
  <Wrapper title="2021 Sessions">
    <div id="main">
      <SessionsList />
    </div>
  </Wrapper>
);

export default Sessions;
